import { useState } from "react";
import { useSelector } from 'react-redux';
import {RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';


export default function SidebarItem({ item, toggleSidebar }) {
    const { user, isLoading } = useSelector(store => store.user)
    const [open, setOpen] = useState(false);

    
    if (item.children) {
        if (!item.access || item.access.includes(user.role_Id)) {
            return (
                <div className={open ? "sidebar-item open" : "sidebar-item"}>
                    <div onClick={() => setOpen(!open)} className={"sidebar-title " + (open ? "nav-link active" : "nav-link")}>

                        {item.icon ? <span className="icon">{item.icon}</span> : ""}
                        {item.title}
                        <span className="icon" >{open ? <RiArrowUpSFill /> : <RiArrowDownSFill />}</span>

                    </div>
                    <div className="sidebar-content">
                        {item.children.map((child) => <SidebarItem key={child.id} item={child} />)}
                    </div>
                </div>
            )
        }
        else {
            return (<></>);
        }
    } else {
        if (!item.access || item.access.includes(user.role_Id)) {
            return (

                <div className="sidebar-item">
                    <div className="sidebar-title">
                        <NavLink to={item.path}
                            className={({ isActive }) => {
                                return isActive ? 'nav-link active' : 'nav-link'
                            }}
                            key={item.id}
                            onClick={toggleSidebar}
                        >
                            {item.icon ? <span className="icon">{item.icon}</span> : ""}
                            {item.title}
                        </NavLink>
                    </div>
                </div>
            );
        }
        else {
            return (<></>);
        }
    }
}