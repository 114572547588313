import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getAllEmployeesThunk = async (_, thunkAPI) => {
    
    const { page, search, limit } = thunkAPI.getState().allEmployees;

    if (!limit)
        limit = 10;

    let url = `/Employee?page=${page}&limit=${limit}`;

    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }
    try {
        const resp = await customFetch.get(url);

        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} slotRecId
 * @param {any} thunkAPI
 */
export const getNotIncludedSlotDayEmployeesThunk = async (params, thunkAPI) => {

    console.log(params);
    const { page, search, limit } =
        thunkAPI.getState().allEmployees;

    if (!limit)
        limit = 10;

    let url = `/Employee/Slot/${params.slotRecId}?page=${page}&limit=${limit}&dayRecId=${params.dayRecId}&notIncluded=1`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} slotRecId
 * @param {any} thunkAPI
 */
export const getNotIncludedSlotDayEmployeesDepThunk = async (params, thunkAPI) => {

    console.log(params);
    const { page, search, limit } =
        thunkAPI.getState().allEmployees;

    if (!limit)
        limit = 10;

    let url = `/Employee/Slot/${params.slotRecId}/Department/${params.departmentRecId}?page=${page}&limit=${limit}&dayRecId=${params.dayRecId}&notIncluded=1`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


/**
 * 
 * @param {any} departmentRecId
 * @param {any} thunkAPI
 */
export const getNotIncludedDepartmentEmployeesThunk = async (departmentRecId, thunkAPI) => {
    const { page, search, limit } =
        thunkAPI.getState().allEmployees;

    if (!limit)
        limit = 10;

    let url = `/Department/Employees/${departmentRecId}?page=${page}&limit=${limit}&notIncluded=1`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


export const getEmployeesRelatedWithUserThunk = async (personRecId, thunkAPI) => {

    const { page, search, limit } = thunkAPI.getState().allEmployees;

    if (!limit)
        limit = 10;

    let url = `/Employee/Related/${personRecId}?page=${page}&limit=${limit}`;

    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }
    try {
        const resp = await customFetch.get(url);

        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};

/**
 * 
 * @param {any} _
 * @param {any} thunkAPI
 */
export const getNotIncludedAuthEmployeesThunk = async (_, thunkAPI) => {
    const { page, search, limit } =
        thunkAPI.getState().allEmployees;

    if (!limit)
        limit = 10;

    let url = `/User/Excluded?page=${page}&limit=${limit}`;
    if (search) {
        url = url + `&search=${search}`;
    }
    else {
        url = url + `&search=""`;
    }

    try {
        const resp = await customFetch.get(url);
        return resp.data;
    } catch (error) {
        return checkForUnauthorizedResponse(error, thunkAPI);
    }
};


export const showStatsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get('/Employee/stats');

    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
