import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
    getAllEmployeesThunk,
    showStatsThunk,
    getNotIncludedSlotDayEmployeesThunk,
    getNotIncludedDepartmentEmployeesThunk,
    getNotIncludedAuthEmployeesThunk,
    getNotIncludedSlotDayEmployeesDepThunk,
    getEmployeesRelatedWithUserThunk,
} from './allEmployeesThunk';

const initialFiltersState = {
  search: '',
 /* searchStatus: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],*/
};

const initialState = {
  isLoading: true,
  employees: [],
  totalEmployees: 0,
  numOfPages: 1,
  page: 1,
  stats: {},
  limit: 10,
  ...initialFiltersState,
};

export const getAllEmployees = createAsyncThunk('allEmployees/getEmployees', getAllEmployeesThunk);
export const showStats = createAsyncThunk('allEmployees/showStats', showStatsThunk);
export const getNotIncludedDepartmentEmployees = createAsyncThunk('allEmployees/getNotIncludedDepartmentEmployees', getNotIncludedDepartmentEmployeesThunk);
export const getNotIncludedSlotDayEmployees = createAsyncThunk('allEmployees/getNotIncludedSlotDayEmployees', getNotIncludedSlotDayEmployeesThunk);
export const getNotIncludedSlotDayEmployeesDep = createAsyncThunk('allEmployees/getNotIncludedSlotDayEmployeesDep', getNotIncludedSlotDayEmployeesDepThunk);
export const getEmployeesRelatedWithUser = createAsyncThunk('allEmployees/getEmployeesRelatedWithUser', getEmployeesRelatedWithUserThunk);
export const getNotIncludedAuthEmployees = createAsyncThunk('allEmployees/getNotIncludedAuthEmployees', getNotIncludedAuthEmployeesThunk);


const allEmployeesSlice = createSlice({
  name: 'allEmployees',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1;
      state[name] = value;
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState };
    },
    changePage: (state, { payload }) => {
      state.page = payload;
    },
    clearAllEmployeesState: (state) => initialState,
    
  },
  extraReducers: {
    [getAllEmployees.pending]: (state) => {
      state.isLoading = true;
    },
    [getAllEmployees.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.employees = payload.employees;
      state.numOfPages = payload.numOfPages;
      state.totalEmployees = payload.totalEmployees;

    },
    [getAllEmployees.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
    [getEmployeesRelatedWithUser.pending]: (state) => {
        state.isLoading = true;
    },
    [getEmployeesRelatedWithUser.fulfilled]: (state, { payload }) => {
        state.isLoading = false;
        state.employees = payload.employees;
        state.numOfPages = payload.numOfPages;
        state.totalEmployees = payload.totalEmployees;

    },
    [getEmployeesRelatedWithUser.rejected]: (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
    },
      /*USERS*/
    [getNotIncludedAuthEmployees.pending]: (state) => {
        state.areEmployeesLoading = true;
    },
    [getNotIncludedAuthEmployees.fulfilled]: (state, { payload }) => {
        state.areEmployeesLoading = false;
        state.employees = payload.employees;
        state.numOfPages = payload.numOfPages;
        state.totalEmployees = payload.totalEmployees;

    },
    [getNotIncludedAuthEmployees.rejected]: (state, { payload }) => {
        state.areEmployeesLoading = false;
        toast.error(payload);
    },
    /*SLOT*/
     [getNotIncludedSlotDayEmployees.pending]: (state) => {
        state.areEmployeesLoading = true;
    },
     [getNotIncludedSlotDayEmployees.fulfilled]: (state, { payload }) => {
        state.areEmployeesLoading = false;
        state.employees = payload.employees;
        state.numOfPages = payload.numOfPages;
        state.totalEmployees = payload.totalEmployees;

    },
     [getNotIncludedSlotDayEmployees.rejected]: (state, { payload }) => {
        state.areEmployeesLoading = false;
        toast.error(payload);
      },
      [getNotIncludedSlotDayEmployeesDep.pending]: (state) => {
          state.areEmployeesLoading = true;
      },
      [getNotIncludedSlotDayEmployeesDep.fulfilled]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          state.employees = payload.employees;
          state.numOfPages = payload.numOfPages;
          state.totalEmployees = payload.totalEmployees;

      },
      [getNotIncludedSlotDayEmployeesDep.rejected]: (state, { payload }) => {
          state.areEmployeesLoading = false;
          toast.error(payload);
      },

    /*DEPARTMENT*/
    [getNotIncludedDepartmentEmployees.pending]: (state) => {
        state.areEmployeesLoading = true;
    },
    [getNotIncludedDepartmentEmployees.fulfilled]: (state, { payload }) => {
        state.areEmployeesLoading = false;
        state.employees = payload.employees;
        state.numOfPages = payload.numOfPages;
        state.totalEmployees = payload.totalEmployees;

    },
    [getNotIncludedDepartmentEmployees.rejected]: (state, { payload }) => {
        state.areEmployeesLoading = false;
        toast.error(payload);
    },
    [showStats.pending]: (state) => {
      state.isLoading = true;
    },
    [showStats.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.stats = payload.defaultStats;
      state.monthlyApplications = payload.monthlyApplications;
    },
    [showStats.rejected]: (state, { payload }) => {
      state.isLoading = false;
      toast.error(payload);
    },
  },
});


export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllEmployeesState,
} = allEmployeesSlice.actions;

export default allEmployeesSlice.reducer;
